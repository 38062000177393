import React, { Component } from 'react';
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import UploadImage from '../utils/UploadImage';
import appBackgroundImage from '../../styles/images/trivia_background.png';
import sampleAppFrontImage from '../../styles/images/front_icon.png';
import sampleAppTopImage from '../../styles/images/top_icon.png';
import sampleScoreboardTopImage from '../../styles/images/top_scoreboard.png';
import { app, appDatabasePrimaryFunctions } from '../../base';
import { getDatabase, ref, onValue, set, off } from 'firebase/database';
import '../../styles/css/AdminMain.css';
import { showSuccessPopup, showErrorPopup, showInfoPopup } from '../utils/SwalConfig';
import { Modal } from 'reactstrap';
import { PhotoshopPicker } from 'react-color';
import RichTextEditor from 'html-verify-react-rte';
import { returnCorrectRichTextObject, readyRichTextForSubmit } from "../utils/HelpfulFunction";

const toolbarConfig = {
    display: ['INLINE_STYLE_BUTTONS', 'LINK_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
        { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
        { label: 'Italic', style: 'ITALIC' },
        { label: 'Underline', style: 'UNDERLINE' },
    ],
};

class SetUpTeamVariables extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
            tenantVariables: {},
            showTabOneMajor: true,
            showTabTwoMajor: false,
            showTabThreeMajor: false,
            showTabOneMinor: true,
            showTabTwoMinor: false,
            showTabThreeMinor: false,
            showTabOneFan: true,
            showTabTwoFan: false,
            showTabThreeFan: false,
            loading: true,
            colorToShow: "#ffffff",
            colorToUpdate: "",
            colorPickerShow: false,
            colorsTab: true,
            formQuestions: [],
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    }

    componentDidMount() {
        const db = getDatabase(app);
        const tenantVariablesRef = ref(db, 'tenantVariables');
        const formQuestionsRef = ref(db, 'formQuestions');

        this.tenantVariablesListener = onValue(tenantVariablesRef, (snapshot) => {
            const tenantVariables = snapshot.val() || {};
            let advanced = false;
            let advancedTextChanges = false;
            if (tenantVariables.formHeaderText) {
                advanced = true;
            }
            if (tenantVariables.globalPrizeWonHeader || tenantVariables.globalPrizeWonBody || tenantVariables.globalNoPrizeHeader || tenantVariables.globalNoPrizeBody || tenantVariables.globalNoneCorrectPrizeHeader || tenantVariables.globalNoneCorrectPrizeBody) {
                advancedTextChanges = true;
            }
            this.setState({
                tenantVariables: tenantVariables,
                advanced: advanced,
                advancedTextChanges: advancedTextChanges,
                loading: false,
            });
        });

        this.formQuestionsListener = onValue(formQuestionsRef, (snapshot) => {
            const formQuestions = snapshot.val() ? Object.values(snapshot.val()) : [];
            this.setState({
                formQuestions: formQuestions,
                loading: false,
            });
        });
    }

    componentWillUnmount() {
        const db = getDatabase(app);
        off(ref(db, 'tenantVariables'), this.tenantVariablesListener);
        off(ref(db, 'formQuestions'), this.formQuestionsListener);
    }

    handleSubmit(event) {
        event.preventDefault();
        const vm = this;
        const variables = { ...this.state.tenantVariables };
        variables.thanksForVotingMessage = readyRichTextForSubmit(variables.thanksForVotingMessage);
        variables.noMoreVotesAllowedMessage = readyRichTextForSubmit(variables.noMoreVotesAllowedMessage);
        variables.gdprRulesAndRegsMessage = readyRichTextForSubmit(variables.gdprRulesAndRegsMessage);
        variables.confirmLockedInMessage = readyRichTextForSubmit(variables.confirmLockedInMessage);
        variables.allAnswersNotFilledOutMessage = readyRichTextForSubmit(variables.allAnswersNotFilledOutMessage);
        variables.showScoreboardPercentages = variables.showScoreboardPercentages || 'all';
        variables.thanksForSubmittingFormBody = readyRichTextForSubmit(variables.thanksForSubmittingFormBody);
        variables.showFanPercentages = variables.showFanPercentages || 'all';
        if (!this.state.advanced) {
            variables.formHeaderText = "";
            variables.otherResponse = false;
        }
        this.setState({ loading: true });
        const db = getDatabase(app);
        set(ref(db, 'tenantVariables'), variables)
            .then(() => {
                vm.setState({ loading: false });
                showSuccessPopup('Tenant Variables Updated!', 'Tenant variables have been successfully updated.');
            })
            .catch((err) => {
                vm.setState({ loading: false });
                showErrorPopup('There was some error!', 'Try again and if the problem persists try logging out and logging back in.');
                console.error(err);
            });
    }

    handleChange(evt) {
        let target = evt.target;
        let value = target.value;
        if (target.type === 'checkbox') {
            value = target.checked;
        }
        this.setState(prevState => ({
            tenantVariables: {
                ...prevState.tenantVariables,
                [target.name]: value
            }
        }));
    }

    handleImageChange(event) {
        const name_of_file = event.target.name;
        const file_to_update = event.target.files[0];
        this.setState({ loading: true });
        const vm = this;
        this.UploadImage.upload_file(file_to_update).then(res => {
            vm.setState({ loading: false });
            if (res.error) {
                showInfoPopup('Image cannot be uploaded', res.error);
            } else if (res.imageUrl) {
                vm.setState(prevState => ({
                    tenantVariables: {
                        ...prevState.tenantVariables,
                        [name_of_file]: res.imageUrl
                    }
                }));
            } else {
                showInfoPopup('Image cannot be uploaded', 'There was some issue with the image upload, please check the image type and size and try again.');
            }
        });
    }

    openColorPicker(colorToChange) {
        const oldColor = this.state.tenantVariables[colorToChange];
        this.setState({
            colorPickerShow: true,
            colorToShow: oldColor,
            colorToUpdate: colorToChange
        });
    }

    handleChangeComplete = (color) => {
        this.setState({ colorToShow: color.hex });
    };

    acceptPicker() {
        const colorToChange = this.state.colorToUpdate;
        this.setState(prevState => ({
            tenantVariables: {
                ...prevState.tenantVariables,
                [colorToChange]: prevState.colorToShow
            },
            updateHappend: true,
            colorPickerShow: false,
        }));
    }

    dismissPicker() {
        this.setState({ colorPickerShow: false });
    }

    toggleColors() {
        if (this.state.colorsTab) {
            document.getElementById('showColors').classList.remove('active');
            document.getElementById('showImages').classList.add('active');
        } else {
            document.getElementById('showColors').classList.add('active');
            document.getElementById('showImages').classList.remove('active');
        }
        this.setState(prevState => ({ colorsTab: !prevState.colorsTab }));
    }

    tripleToggleControl(element, whichToggle) {
        const tabOne = "showTabOne" + whichToggle;
        const tabTwo = "showTabTwo" + whichToggle;
        const tabThree = "showTabThree" + whichToggle;
        if (element === "first") {
            document.getElementById('showsecond' + whichToggle).classList.remove('active');
            document.getElementById('showfirst' + whichToggle).classList.add('active');
            document.getElementById('showthird' + whichToggle).classList.remove('active');
            this.setState({
                [tabOne]: true,
                [tabTwo]: false,
                [tabThree]: false,
            });
        } else if (element === "second") {
            document.getElementById('showfirst' + whichToggle).classList.remove('active');
            document.getElementById('showsecond' + whichToggle).classList.add('active');
            document.getElementById('showthird' + whichToggle).classList.remove('active');
            this.setState({
                [tabOne]: false,
                [tabTwo]: true,
                [tabThree]: false,
            });
        } else {
            document.getElementById('showfirst' + whichToggle).classList.remove('active');
            document.getElementById('showthird' + whichToggle).classList.add('active');
            document.getElementById('showsecond' + whichToggle).classList.remove('active');
            this.setState({
                [tabOne]: false,
                [tabTwo]: false,
                [tabThree]: true,
            });
        }
    }

    handleRichTextChange = (name, text) => {
        this.setState(prevState => ({
            tenantVariables: {
                ...prevState.tenantVariables,
                [name]: text
            }
        }));
    }

    createFormResponse() {
        const formResponse = this.state.formQuestionText || "";
        if (!formResponse) {
            showInfoPopup("Missing Text", "Please make sure to add some text in the above input.");
            return;
        }
        const formResponseId = appDatabasePrimaryFunctions.ref().push().key;
        this.setState({ loading: true });
        const vm = this;
        const objectToSave = {
            id: formResponseId,
            text: formResponse
        };
        set(ref(getDatabase(app), `formQuestions/${formResponseId}`), objectToSave).then(() => {
            vm.setState({
                loading: false,
                formQuestionText: ''
            });
        });
    }

    removeFormQuestion(itemId) {
        set(ref(getDatabase(app), `formQuestions/${itemId}`), null).then(() => {
            showErrorPopup('There was some error!', 'Try again and if the problem persists try logging out and logging back in.');
        });
    }

    handleCheckBoxChange(evt) {
        let target = evt.target;
        let value = target.checked;
        this.setState({ [target.name]: value });
    }

    render() {
        const tenantVariables = this.state.tenantVariables || {};
        const backgroundImage = tenantVariables.backgroundImage || appBackgroundImage;
        const topAnswerImage = tenantVariables.topAnswerImage || sampleAppTopImage;
        const frontImage = tenantVariables.frontLogoImage || sampleAppFrontImage;
        const teamLogo = tenantVariables.topLeftImage || sampleAppTopImage;
        const leftOfScoreboardImage = tenantVariables.leftOfScoreboardImage || "";
        const showScoreboardPercentages = tenantVariables.showScoreboardPercentages || "all";
        const showFanPercentages = tenantVariables.showFanPercentages || "all";
        const scoreboardBackground = tenantVariables.scoreboardBackgroundImage || appBackgroundImage;
        const leftScoreboardBackground = tenantVariables.leftScoreboardBackground || appBackgroundImage;
        const topScoreboardImage = tenantVariables.topOfScoreboardImage || sampleScoreboardTopImage;
        let thanksForVotingMessage = returnCorrectRichTextObject(tenantVariables.thanksForVotingMessage);
        let noMoreVotesAllowedMessage = returnCorrectRichTextObject(tenantVariables.noMoreVotesAllowedMessage);
        let gdprRulesAndRegsMessage = returnCorrectRichTextObject(tenantVariables.gdprRulesAndRegsMessage);
        let confirmLockedInMessage = returnCorrectRichTextObject(tenantVariables.confirmLockedInMessage);
        let allAnswersNotFilledOutMessage = returnCorrectRichTextObject(tenantVariables.allAnswersNotFilledOutMessage);
        const thanksForSubmittingFormBody = returnCorrectRichTextObject(tenantVariables.thanksForSubmittingFormBody);
        const aboveLoginFormText = tenantVariables.aboveLoginFormText || "";

        return (
            <div className="admin-wrapper">
                <div className="loading-screen" style={{ display: this.state.loading ? 'block' : 'none' }} />
                <SideMenu />
                <TopMenu />
                <div className="admin-main-panel">
                    <p className="admin-header-text" style={{ marginBottom: 0, marginTop: '5px' }}>Game Branding</p>
                    <p className="admin-subheader-text">This is where you can edit the game branding on your activation</p>
                    <div className="container-out">
                        <div className="admin-form-box" style={{ marginTop: '0px', paddingTop: '5px' }}>
                            <form onSubmit={this.handleSubmit} id="create-game-form">
                                <button className="btn btn-primary btn-lg update-button" id="submitButton" style={{ marginBottom: '20px' }}><span className="fa fa-arrow-circle-o-up"></span> Update</button>
                                <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                                    <li className="nav-item" onClick={() => this.tripleToggleControl('first', 'Major')}>
                                        <a href="#" aria-expanded="false" className="nav-link active" id="showfirstMajor">
                                            <i className="mdi mdi-home-variant d-lg-none d-block mr-1" />
                                            <span className="d-none d-lg-block">Fan Color/Images</span>
                                        </a>
                                    </li>
                                    <li className="nav-item" onClick={() => this.tripleToggleControl('second', 'Major')}>
                                        <a href="#" aria-expanded="true" className="nav-link" id="showsecondMajor">
                                            <i className="mdi mdi-home-variant d-lg-none d-block mr-1" />
                                            <span className="d-none d-lg-block">Scoreboard Color/Images</span>
                                        </a>
                                    </li>
                                    <li className="nav-item" onClick={() => this.tripleToggleControl('third', 'Major')}>
                                        <a href="#" aria-expanded="true" className="nav-link" id="showthirdMajor">
                                            <i className="mdi mdi-account-circle d-lg-none d-block mr-1" />
                                            <span className="d-none d-lg-block">Game Text</span>
                                        </a>
                                    </li>
                                </ul>
                                <ul className="nav nav-tabs nav-justified nav-bordered mb-3" style={{ display: this.state.showTabOneMajor === true ? '' : 'none' }}>
                                    <li className="nav-item" onClick={() => this.tripleToggleControl('first', 'Fan')}>
                                        <a href="#" aria-expanded="false" className="nav-link active" id="showfirstFan">
                                            <i className="mdi mdi-home-variant d-lg-none d-block mr-1" />
                                            <span className="d-none d-lg-block">Colors</span>
                                        </a>
                                    </li>
                                    <li className="nav-item" onClick={() => this.tripleToggleControl('second', 'Fan')}>
                                        <a href="#" aria-expanded="true" className="nav-link" id="showsecondFan">
                                            <i className="mdi mdi-home-variant d-lg-none d-block mr-1" />
                                            <span className="d-none d-lg-block">Images</span>
                                        </a>
                                    </li>
                                    <li className="nav-item" onClick={() => this.tripleToggleControl('third', 'Fan')}>
                                        <a href="#" aria-expanded="true" className="nav-link" id="showthirdFan">
                                            <i className="mdi mdi-home-variant d-lg-none d-block mr-1" />
                                            <span className="d-none d-lg-block">Display</span>
                                        </a>
                                    </li>
                                </ul>
                                <ul className="nav nav-tabs nav-justified nav-bordered mb-3" style={{ display: this.state.showTabTwoMajor === true ? '' : 'none' }}>
                                    <li className="nav-item" onClick={() => this.tripleToggleControl("first", "Minor")}>
                                        <a href="#" aria-expanded="false" className="nav-link active" id="showfirstMinor">
                                            <i className="mdi mdi-home-variant d-lg-none d-block mr-1" />
                                            <span className="d-none d-lg-block">Colors</span>
                                        </a>
                                    </li>
                                    <li className="nav-item" onClick={() => this.tripleToggleControl("second", "Minor")}>
                                        <a href="#" aria-expanded="true" className="nav-link" id="showsecondMinor">
                                            <i className="mdi mdi-home-variant d-lg-none d-block mr-1" />
                                            <span className="d-none d-lg-block">Images</span>
                                        </a>
                                    </li>
                                    <li className="nav-item" onClick={() => this.tripleToggleControl("third", "Minor")}>
                                        <a href="#" aria-expanded="true" className="nav-link" id="showthirdMinor">
                                            <i className="mdi mdi-home-variant d-lg-none d-block mr-1" />
                                            <span className="d-none d-lg-block">Timing/Sizes</span>
                                        </a>
                                    </li>
                                </ul>
                                <div style={{ display: this.state.showTabOneMajor ? 'block' : 'none' }}>
                                    <div style={{ display: this.state.showTabOneFan ? 'block' : 'none' }}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h4>Primary Colors</h4>
                                                <div className="form-inline">
                                                    <span style={{ marginRight: 10 }} className="fa fa-eyedropper mobile-hide" onClick={() => this.openColorPicker("primaryColor")} />
                                                    <div style={{ backgroundColor: this.state.tenantVariables.primaryColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block" }} />
                                                    <label htmlFor="primaryColor" style={{ marginRight: 10 }}>Button Background Color</label>
                                                    <input id="primaryColor" name="primaryColor" type="text" className="form-control" value={this.state.tenantVariables.primaryColor} onChange={this.handleChange} placeholder="#000" style={{ marginRight: 10 }} />

                                                    <div style={{ height: '10px', width: '100%' }} />

                                                    <span style={{ marginRight: 10 }} className="fa fa-eyedropper mobile-hide" onClick={() => this.openColorPicker("secondaryColor")} />
                                                    <div style={{ backgroundColor: this.state.tenantVariables.secondaryColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block" }} />
                                                    <label htmlFor="secondaryColor" style={{ marginRight: 10 }}>Button Text Color</label>
                                                    <input id="secondaryColor" name="secondaryColor" type="text" className="form-control" value={this.state.tenantVariables.secondaryColor} onChange={this.handleChange} placeholder="#000" />

                                                    <div style={{ height: '10px', width: '100%' }} />

                                                    <span style={{ marginRight: 10 }} className="fa fa-eyedropper mobile-hide" onClick={() => this.openColorPicker("userQuestionColor")} />
                                                    <div style={{ backgroundColor: this.state.tenantVariables.userQuestionColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block" }} />
                                                    <label htmlFor="userQuestionColor" style={{ marginRight: 10 }}>Question Color</label>
                                                    <input id="userQuestionColor" name="userQuestionColor" type="text" className="form-control" value={this.state.tenantVariables.userQuestionColor} onChange={this.handleChange} placeholder="#000" />

                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <h4>Answer Button Colors</h4>
                                                <div className="form-inline">
                                                    <span style={{ marginRight: 10 }} className="fa fa-eyedropper mobile-hide" onClick={() => this.openColorPicker("answerBackgroundColor")} />
                                                    <div style={{ backgroundColor: this.state.tenantVariables.answerBackgroundColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block" }} />
                                                    <label htmlFor="answerBackgroundColor" style={{ marginRight: 10 }}>Background Color</label>
                                                    <input id="answerBackgroundColor" name="answerBackgroundColor" type="text" className="form-control" value={this.state.tenantVariables.answerBackgroundColor} onChange={this.handleChange} placeholder="#000" style={{ marginRight: 10 }} />

                                                    <div style={{ height: '10px', width: '100%' }} />

                                                    <span style={{ marginRight: 10 }} className="fa fa-eyedropper mobile-hide" onClick={() => this.openColorPicker("answerTextColor")} />
                                                    <div style={{ backgroundColor: this.state.tenantVariables.answerTextColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block" }} />
                                                    <label htmlFor="answerTextColor" style={{ marginRight: 10 }}>Text Color</label>
                                                    <input id="answerTextColor" name="answerTextColor" type="text" className="form-control" value={this.state.tenantVariables.answerTextColor} onChange={this.handleChange} placeholder="#000" style={{ marginRight: 10 }} />

                                                    <div style={{ height: '10px', width: '100%' }} />

                                                    <span style={{ marginRight: 10 }} className="fa fa-eyedropper mobile-hide" onClick={() => this.openColorPicker("answerOutlineColor")} />
                                                    <div style={{ backgroundColor: this.state.tenantVariables.answerOutlineColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block" }} />
                                                    <label htmlFor="answerOutlineColor" style={{ marginRight: 10 }}>Outline Color</label>
                                                    <input id="answerOutlineColor" name="answerOutlineColor" type="text" className="form-control" value={this.state.tenantVariables.answerOutlineColor} onChange={this.handleChange} placeholder="#000" style={{ marginRight: 10 }} />

                                                    <div style={{ height: '10px', width: '100%' }} />

                                                    <span style={{ marginRight: 10 }} className="fa fa-eyedropper mobile-hide" onClick={() => this.openColorPicker("answerProgressBarClient")} />
                                                    <div style={{ backgroundColor: this.state.tenantVariables.answerProgressBarClient, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block" }} />
                                                    <label htmlFor="answerProgressBarClient" style={{ marginRight: 10 }}>Progress Bar Color</label>
                                                    <input id="answerProgressBarClient" name="answerProgressBarClient" type="text" className="form-control" value={this.state.tenantVariables.answerProgressBarClient} onChange={this.handleChange} placeholder="#000" style={{ marginRight: 10 }} />

                                                    <div style={{ height: '10px', width: '100%' }} />

                                                    <span style={{ marginRight: 10 }} className="fa fa-eyedropper mobile-hide" onClick={() => this.openColorPicker("answerDescriptionBackgroundColor")} />
                                                    <div style={{ backgroundColor: this.state.tenantVariables.answerDescriptionBackgroundColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block" }} />
                                                    <label htmlFor="answerDescriptionBackgroundColor" style={{ marginRight: 10 }}>Answer Description Background Color</label>
                                                    <input id="answerDescriptionBackgroundColor" name="answerDescriptionBackgroundColor" type="text" className="form-control" value={this.state.tenantVariables.answerDescriptionBackgroundColor} onChange={this.handleChange} placeholder="#000" style={{ marginRight: 10 }} />

                                                    <div style={{ height: '10px', width: '100%' }} />

                                                    <span style={{ marginRight: 10 }} className="fa fa-eyedropper mobile-hide" onClick={() => this.openColorPicker("answerDescriptionTextColor")} />
                                                    <div style={{ backgroundColor: this.state.tenantVariables.answerDescriptionTextColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block" }} />
                                                    <label htmlFor="answerDescriptionTextColor" style={{ marginRight: 10 }}>Answer Description Text Color</label>
                                                    <input id="answerDescriptionTextColor" name="answerDescriptionTextColor" type="text" className="form-control" value={this.state.tenantVariables.answerDescriptionTextColor} onChange={this.handleChange} placeholder="#000" style={{ marginRight: 10 }} />
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ height: '10px', width: '100%' }} />
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h4>Answer Action Colors</h4>
                                                <div className="form-inline">
                                                    <span style={{ marginRight: 10 }} className="fa fa-eyedropper mobile-hide" onClick={() => this.openColorPicker("correctTextColor")} />
                                                    <div style={{ backgroundColor: this.state.tenantVariables.correctTextColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block" }} />
                                                    <label htmlFor="correctTextColor" style={{ marginRight: 10 }}>High Vote Color</label>
                                                    <input id="correctTextColor" name="correctTextColor" type="text" className="form-control" value={this.state.tenantVariables.correctTextColor} onChange={this.handleChange} placeholder="#000" style={{ marginRight: 10 }} />

                                                    <div style={{ height: '10px', width: '100%' }} />

                                                    <span style={{ marginRight: 10 }} className="fa fa-eyedropper mobile-hide" onClick={() => this.openColorPicker("lastVoteColor")} />
                                                    <div style={{ backgroundColor: this.state.tenantVariables.lastVoteColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block" }} />
                                                    <label htmlFor="lastVoteColor" style={{ marginRight: 10 }}>Last Vote Color</label>
                                                    <input id="lastVoteColor" name="lastVoteColor" type="text" className="form-control" value={this.state.tenantVariables.lastVoteColor} onChange={this.handleChange} placeholder="#000" style={{ marginRight: 10 }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <h4>Log Out Colors</h4>
                                                <div className="form-inline">
                                                    <span style={{ marginRight: 10 }} className="fa fa-eyedropper mobile-hide" onClick={() => this.openColorPicker("logOutButtonColor")} />
                                                    <div style={{ backgroundColor: this.state.tenantVariables.logOutButtonColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block" }} />
                                                    <label htmlFor="logOutButtonColor" style={{ marginRight: 10 }}>Log Out Button Color</label>
                                                    <input id="logOutButtonColor" name="logOutButtonColor" type="text" className="form-control" value={this.state.tenantVariables.logOutButtonColor} onChange={this.handleChange} placeholder="#000" style={{ marginRight: 10 }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: this.state.showTabTwoFan ? 'block' : 'none' }}>
                                        <div className="admin-grid-container three-columns" style={{ float: 'left', display: 'flex', alignItems: 'flex-end' }}>
                                            <div className="form-group" style={{ textAlign: 'center', margin: 20 }}>
                                                <img src={backgroundImage} width="160" height="auto" alt="" />
                                                <br />
                                                <label htmlFor="backgroundImage">Phone Background Image<br />(1654px X 926px)</label>
                                                <div className="form-group">
                                                    <input style={{ display: 'none' }} id="backgroundImage" name="backgroundImage" type="file" ref={input => { this.backgroundImage = input; }} onChange={this.handleImageChange} />
                                                    <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('backgroundImage').click()} />
                                                </div>
                                            </div>
                                            <div className="form-group" style={{ textAlign: 'center', margin: 20 }}>
                                                <img src={frontImage} width="150" height="auto" alt="" />
                                                <br />
                                                <label htmlFor="frontLogoImage">Front Logo<br />(620px X 732px)</label>
                                                <div className="form-group">
                                                    <input style={{ display: 'none' }} id="frontLogoImage" name="frontLogoImage" type="file" ref={input => { this.frontLogoImage = input; }} onChange={this.handleImageChange} />
                                                    <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('frontLogoImage').click()} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="admin-grid-container three-columns" style={{ float: 'left', display: 'flex', alignItems: 'flex-end' }}>
                                            <div className="form-group" style={{ float: 'left', textAlign: 'center', margin: 20 }}>
                                                <img src={teamLogo} width="100" height="auto" alt="" />
                                                <br />
                                                <label htmlFor="topLeftImage">Top Logo<br />(398px X 470px)</label>
                                                <div className="form-group">
                                                    <input style={{ display: 'none' }} id="topLeftImage" name="topLeftImage" type="file" ref={input => { this.topLeftImage = input; }} onChange={this.handleImageChange} />
                                                    <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('topLeftImage').click()} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: this.state.showTabThreeFan ? 'block' : 'none' }}>
                                        <div className="row">
                                            <div className="form-group row mb-3">
                                                <label className="col-md-12 col-form-label" htmlFor="name3"> Show Percentages On The Users Phone</label>
                                                <div className="col-md-9" onChange={this.handleChange}>
                                                    <input type="radio" value="all" name="showFanPercentages" checked={showFanPercentages === "all"} /> <span style={{ marginRight: 10, fontSize: 10 }}>The Entire Game</span>
                                                    <input type="radio" value="parttime" name="showFanPercentages" checked={showFanPercentages === "parttime"} /> <span style={{ marginRight: 10, fontSize: 10 }}>After The Game Ends</span>
                                                    <input type="radio" value="never" name="showFanPercentages" checked={showFanPercentages === "never"} /> <span style={{ fontSize: 10 }}>Never</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: this.state.showTabTwoMajor ? 'block' : 'none' }}>
                                    <div style={{ display: this.state.showTabOneMinor ? 'block' : 'none' }}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h4>Scoreboard Colors</h4>
                                                <div className="form-inline">
                                                    <span style={{ marginRight: 10 }} className="fa fa-eyedropper mobile-hide" onClick={() => this.openColorPicker("progressBarBackgroundColor")} />
                                                    <div style={{ backgroundColor: this.state.tenantVariables.progressBarBackgroundColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block" }} />
                                                    <label htmlFor="progressBarBackgroundColor" style={{ marginRight: 10 }}>Progress Bar Background Color</label>
                                                    <input id="progressBarBackgroundColor" name="progressBarBackgroundColor" type="text" className="form-control" value={this.state.tenantVariables.progressBarBackgroundColor} onChange={this.handleChange} placeholder="#000" style={{ marginRight: 10 }} />

                                                    <span style={{ marginRight: 10 }} className="fa fa-eyedropper mobile-hide" onClick={() => this.openColorPicker("progressBarColor")} />
                                                    <div style={{ backgroundColor: this.state.tenantVariables.progressBarColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block" }} />
                                                    <label htmlFor="progressBarColor" style={{ marginRight: 10 }}>Progress Bar Color</label>
                                                    <input id="progressBarColor" name="progressBarColor" type="text" className="form-control" value={this.state.tenantVariables.progressBarColor} onChange={this.handleChange} placeholder="#000" />

                                                    <div style={{ height: '10px', width: '100%' }} />

                                                    <span style={{ marginRight: 10 }} className="fa fa-eyedropper mobile-hide" onClick={() => this.openColorPicker("progressBarTextColor")} />
                                                    <div style={{ backgroundColor: this.state.tenantVariables.progressBarTextColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block" }} />
                                                    <label htmlFor="progressBarTextColor" style={{ marginRight: 10 }}>Progress Bar Text Color</label>
                                                    <input id="progressBarTextColor" name="progressBarTextColor" type="text" className="form-control" value={this.state.tenantVariables.progressBarTextColor} onChange={this.handleChange} placeholder="#000" style={{ marginRight: 10 }} />

                                                    <span style={{ marginRight: 10 }} className="fa fa-eyedropper mobile-hide" onClick={() => this.openColorPicker("progressBarQuestionTextColor")} />
                                                    <div style={{ backgroundColor: this.state.tenantVariables.progressBarQuestionTextColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block" }} />
                                                    <label htmlFor="progressBarQuestionTextColor" style={{ marginRight: 10 }}>Question Text Color</label>
                                                    <input id="progressBarQuestionTextColor" name="progressBarQuestionTextColor" type="text" className="form-control" value={this.state.tenantVariables.progressBarQuestionTextColor} onChange={this.handleChange} placeholder="#000" />

                                                    <div style={{ height: '10px', width: '100%' }} />

                                                    <span style={{ marginRight: 10 }} className="fa fa-eyedropper mobile-hide" onClick={() => this.openColorPicker("progressBarEndWinColor")} />
                                                    <div style={{ backgroundColor: this.state.tenantVariables.progressBarEndWinColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block" }} />
                                                    <label htmlFor="progressBarEndWinColor" style={{ marginRight: 10 }}>Progress Bar Most Votes Color</label>
                                                    <input id="progressBarEndWinColor" name="progressBarEndWinColor" type="text" className="form-control" value={this.state.tenantVariables.progressBarEndWinColor} onChange={this.handleChange} placeholder="#000" />

                                                    <span style={{ marginRight: 10 }} className="fa fa-eyedropper mobile-hide" onClick={() => this.openColorPicker("progressBarOutlineScoreboardColor")} />
                                                    <div style={{ backgroundColor: this.state.tenantVariables.progressBarOutlineScoreboardColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block" }} />
                                                    <label htmlFor="progressBarOutlineScoreboardColor" style={{ marginRight: 10 }}>Progress Bar Outline Color</label>
                                                    <input id="progressBarOutlineScoreboardColor" name="progressBarOutlineScoreboardColor" type="text" className="form-control" value={this.state.tenantVariables.progressBarOutlineScoreboardColor} onChange={this.handleChange} placeholder="#000" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: this.state.showTabTwoMinor ? 'block' : 'none' }}>
                                        <div className="admin-grid-container two-columns">
                                            <div style={{ textAlign: 'center' }}>
                                                <h4>Scoreboard With Top Image</h4>
                                                <h6>{process.env.REACT_APP_SCOREBOARD_TOP_LINK}</h6>
                                                <div style={{ height: '10px', width: '100%' }} />
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group" style={{ textAlign: 'center' }}>
                                                            <img src={scoreboardBackground} width="160" height="auto" alt="" />
                                                            <br />
                                                            <label htmlFor="scoreboardBackgroundImage">Scoreboard Background Image<br />(1654px X 926px)</label>
                                                            <div className="form-group">
                                                                <input style={{ display: 'none' }} id="scoreboardBackgroundImage" name="scoreboardBackgroundImage" type="file" ref={input => { this.scoreboardBackgroundImage = input; }} onChange={this.handleImageChange} />
                                                                <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('scoreboardBackgroundImage').click()} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group" style={{ textAlign: 'center' }}>
                                                            <img src={topScoreboardImage} width="160" height="auto" alt="" />
                                                            <br />
                                                            <label htmlFor="topOfScoreboardImage">Top Of Scoreboard Image<br />(1191px X 200px)</label>
                                                            <div className="form-group">
                                                                <input style={{ display: 'none' }} id="topOfScoreboardImage" name="topOfScoreboardImage" type="file" ref={input => { this.topOfScoreboardImage = input; }} onChange={this.handleImageChange} />
                                                                <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('topOfScoreboardImage').click()} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ textAlign: 'center' }}>
                                                <h4>Scoreboard With Left Image</h4>
                                                <h6>{process.env.REACT_APP_SCOREBOARD_SIDE_LINK}</h6>
                                                <div style={{ height: '10px', width: '100%' }} />
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group" style={{ textAlign: 'center' }}>
                                                            <img src={leftScoreboardBackground} width="160" height="auto" alt="" />
                                                            <br />
                                                            <label htmlFor="leftScoreboardBackground">Scoreboard Background Image<br />(1654px X 926px)</label>
                                                            <div className="form-group">
                                                                <input style={{ display: 'none' }} id="leftScoreboardBackground" name="leftScoreboardBackground" type="file" ref={input => { this.leftScoreboardBackground = input; }} onChange={this.handleImageChange} />
                                                                <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('leftScoreboardBackground').click()} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group" style={{ textAlign: 'center' }}>
                                                            <img src={leftOfScoreboardImage} width="160" height="auto" alt="" />
                                                            <br />
                                                            <label htmlFor="leftOfScoreboardImage">Left Of Scoreboard Image<br />(1821px X 2988px)</label>
                                                            <div className="form-group">
                                                                <input style={{ display: 'none' }} id="leftOfScoreboardImage" name="leftOfScoreboardImage" type="file" ref={input => { this.leftOfScoreboardImage = input; }} onChange={this.handleImageChange} />
                                                                <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('leftOfScoreboardImage').click()} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: this.state.showTabThreeMinor ? 'block' : 'none' }}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h4>Timing</h4>
                                                <div className="form-inline">
                                                    <label htmlFor="questionDisplayLength" style={{ marginRight: 10 }}>Question Show Length (secs)</label>
                                                    <input id="questionDisplayLength" name="questionDisplayLength" type="number" className="form-control" value={this.state.tenantVariables.questionDisplayLength} onChange={this.handleChange} placeholder="5" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <h4>Top Image Scoreboard Sizes</h4>
                                                <div className="form-inline">
                                                    <label htmlFor="topScoreboardQuestionSize" style={{ marginRight: 10, marginLeft: 10 }}>Question Size</label>
                                                    <input id="topScoreboardQuestionSize" name="topScoreboardQuestionSize" type="number" className="form-control" value={this.state.tenantVariables.topScoreboardQuestionSize} onChange={this.handleChange} placeholder="44" />
                                                </div>
                                                <div style={{ height: '10px', width: '100%' }} />
                                                <h4>Left Image Scoreboard Sizes</h4>
                                                <div className="form-inline">
                                                    <label htmlFor="leftScoreboardQuestionSize" style={{ marginRight: 10, marginLeft: 10 }}>Question Size</label>
                                                    <input id="leftScoreboardQuestionSize" name="leftScoreboardQuestionSize" type="number" className="form-control" value={this.state.tenantVariables.leftScoreboardQuestionSize} onChange={this.handleChange} placeholder="44" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h4>Display Percents</h4>
                                                <div className="form-group row mb-3">
                                                    <label className="col-md-12 col-form-label" htmlFor="name3"> Show Percentages On The Scoreboard</label>
                                                    <div className="col-md-9" onChange={this.handleChange}>
                                                        <input type="radio" value="all" name="showScoreboardPercentages" checked={showScoreboardPercentages === "all"} /> <span style={{ marginRight: 10, fontSize: 10 }}>The Entire Game</span>
                                                        <input type="radio" value="parttime" name="showScoreboardPercentages" checked={showScoreboardPercentages === "parttime"} /> <span style={{ marginRight: 10, fontSize: 10 }}>After The Game Ends</span>
                                                        <input type="radio" value="never" name="showScoreboardPercentages" checked={showScoreboardPercentages === "never"} /> <span style={{ fontSize: 10 }}>Never</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: this.state.showTabThreeMajor ? 'block' : 'none' }}>
                                    <div className="form-group">
                                        <label htmlFor="pageTitle">Page Title:</label>
                                        <input id="pageTitle" name="pageTitle" type="text" className="form-control" value={this.state.tenantVariables.pageTitle} onChange={this.handleChange} placeholder="Polling" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="playingText">Playing Text:</label>
                                        <input id="playingText" name="playingText" type="text" className="form-control" value={this.state.tenantVariables.playingText} onChange={this.handleChange} placeholder="2nd Quarter" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="letsPlayButtonText">Let's Play Button Text:</label>
                                        <input id="letsPlayButtonText" name="letsPlayButtonText" type="text" className="form-control" value={this.state.tenantVariables.letsPlayButtonText} onChange={this.handleChange} placeholder="Let's Play!" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="thanksForVotingHeader">Thanks For Voting Header</label>
                                        <input id="thanksForVotingHeader" name="thanksForVotingHeader" type="text" className="form-control" value={this.state.tenantVariables.thanksForVotingHeader} onChange={this.handleChange} placeholder="Thanks For Voting!" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="thanksForVotingMessage">Thanks For Voting Message</label>
                                        <RichTextEditor id="thanksForVotingMessage" name="thanksForVotingMessage" toolbarConfig={toolbarConfig} value={thanksForVotingMessage} onChange={(text) => this.handleRichTextChange("thanksForVotingMessage", text)} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="noMoreVotesAllowedHeader">No More Votes Allowed Header</label>
                                        <input id="noMoreVotesAllowedHeader" name="noMoreVotesAllowedHeader" type="text" className="form-control" value={this.state.tenantVariables.noMoreVotesAllowedHeader} onChange={this.handleChange} placeholder="No More Picks" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="noMoreVotesAllowedMessage">No More Votes Allowed Message</label>
                                        <RichTextEditor id="noMoreVotesAllowedMessage" name="noMoreVotesAllowedMessage" toolbarConfig={toolbarConfig} value={noMoreVotesAllowedMessage} onChange={(text) => this.handleRichTextChange("noMoreVotesAllowedMessage", text)} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="confirmLockedInHeader">Confirm Locked In Header</label>
                                        <input id="confirmLockedInHeader" name="confirmLockedInHeader" type="text" className="form-control" value={this.state.tenantVariables.confirmLockedInHeader} onChange={this.handleChange} placeholder="Oh No!" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="confirmLockedInMessage">Confirm Locked In Message</label>
                                        <RichTextEditor id="confirmLockedInMessage" name="confirmLockedInMessage" toolbarConfig={toolbarConfig} value={confirmLockedInMessage} onChange={(text) => this.handleRichTextChange("confirmLockedInMessage", text)} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="allAnswersNotFilledOutHeader">All Answers Not Filled Out Header</label>
                                        <input id="allAnswersNotFilledOutHeader" name="allAnswersNotFilledOutHeader" type="text" className="form-control" value={this.state.tenantVariables.allAnswersNotFilledOutHeader} onChange={this.handleChange} placeholder="Oh No!" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="allAnswersNotFilledOutMessage">All Answers Not Filled Out Message</label>
                                        <RichTextEditor id="allAnswersNotFilledOutMessage" name="allAnswersNotFilledOutMessage" toolbarConfig={toolbarConfig} value={allAnswersNotFilledOutMessage} onChange={(text) => this.handleRichTextChange("allAnswersNotFilledOutMessage", text)} />
                                    </div>
                                    {tenantVariables.gdprRulesAndRegs &&
                                        <>
                                            <div className="form-group">
                                                <label htmlFor="gdprRulesAndRegsHeader">Have Agreed To Rules & Regs Header</label>
                                                <input id="gdprRulesAndRegsHeader" name="gdprRulesAndRegsHeader" type="text" className="form-control" value={this.state.tenantVariables.gdprRulesAndRegsHeader} onChange={this.handleChange} placeholder="" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="gdprRulesAndRegsMessage">Have Agreed To Rules & Regs Message</label>
                                                <RichTextEditor id="gdprRulesAndRegsMessage" name="gdprRulesAndRegsMessage" toolbarConfig={toolbarConfig} value={gdprRulesAndRegsMessage} onChange={(text) => this.handleRichTextChange("gdprRulesAndRegsMessage", text)} />
                                            </div>
                                        </>
                                    }
                                    <div className="form-group">
                                        <label htmlFor="aboveLoginFormText">Above Login Form Text</label>
                                        <input id="aboveLoginFormText" name="aboveLoginFormText" type="text" className="form-control" value={aboveLoginFormText} onChange={this.handleChange} placeholder="Enter your email to vote" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="showAdvancedSettings">Advanced</label>
                                        <br />
                                        <input type="checkbox" checked={this.state.advanced} id="advanced" name="advanced" onChange={this.handleCheckBoxChange} />
                                    </div>
                                    {this.state.advanced &&
                                        <>
                                            <div className="form-group">
                                                <label htmlFor="formHeaderText">Form header text:</label>
                                                <input id="formHeaderText" name="formHeaderText" type="text" className="form-control" value={this.state.tenantVariables.formHeaderText} onChange={this.handleChange} placeholder="Fill out the form for a chance to win a prize!" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="thanksForSubmittingFormHeader">Successful Submitting Form Message Header:</label>
                                                <input id="thanksForSubmittingFormHeader" name="thanksForSubmittingFormHeader" type="text" className="form-control" value={this.state.thanksForSubmittingFormHeader} onChange={this.handleChange} placeholder="Submitted Successfully!" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="thanksForSubmittingFormBody">Successful Submitting Form Message Body:</label>
                                                <RichTextEditor id="thanksForSubmittingFormBody" name="thanksForSubmittingFormBody" toolbarConfig={toolbarConfig} value={thanksForSubmittingFormBody} onChange={(text) => this.handleRichTextChange("thanksForSubmittingFormBody", text)} placeholder="" />
                                            </div>
                                            <h3>Add a response</h3>
                                            <div className="form-group">
                                                <label htmlFor="formQuestionText">Form response text:</label>
                                                <input id="formQuestionText" name="formQuestionText" type="text" className="form-control" value={this.state.formQuestionText} onChange={this.handleChange} placeholder="A option on form" />
                                            </div>
                                            <button onClick={() => this.createFormResponse()} type="button" className="btn btn-primary btn-lg update-button" id="addFormQuestion" style={{ marginBottom: '20px' }}><span className="fa fa-arrow-circle-o-up" /> Add Form Response</button>
                                            <div className="form-group">
                                                <label htmlFor="otherResponse">Add an OTHER response checkbox</label>
                                                <br />
                                                <input type="checkbox" checked={this.state.tenantVariables.otherResponse} id="otherResponse" name="otherResponse" onChange={this.handleChange} />
                                            </div>
                                            <h3>Responses On Form</h3>
                                            {this.state.formQuestions && this.state.formQuestions.length > 0 &&
                                                this.state.formQuestions.map((item, i) => (
                                                    <div key={i}>
                                                        {item.text} <span style={{ cursor: "pointer" }} onClick={() => this.removeFormQuestion(item.id)}>❌</span>
                                                    </div>
                                                ))
                                            }
                                            {(!this.state.formQuestions || this.state.formQuestions.length === 0) &&
                                                <h5>No Questions Added!</h5>
                                            }
                                        </>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.colorPickerShow} toggle={this.state.colorPickerShow} id="myModal">
                    <PhotoshopPicker onChangeComplete={this.handleChangeComplete} color={this.state.colorToShow} onAccept={() => this.acceptPicker()} onCancel={() => this.dismissPicker()} />
                </Modal>
            </div>
        );
    }
}

export default SetUpTeamVariables;
