import Swal from 'sweetalert2';

const swalConfig = Swal.mixin({
    confirmButtonText: 'Ok',
    buttonsStyling: true,
});

export const showSuccessPopup = async (title, text) => {
    return swalConfig.fire({
        title: title,
        text: text,
        icon: 'success',
    });
};

export const showErrorPopup = async (title, text) => {
    return swalConfig.fire({
        title: title,
        text: text,
        icon: 'error',
    });
};

export const showInfoPopup = async (title, text) => {
    return swalConfig.fire({
        title: title,
        text: text,
        icon: 'info',
    });
};

export const showInputPopup = async (title, text, placeholder, validationFn = null) => {
    const result = await swalConfig.fire({
        title: title,
        text: text,
        input: 'text',
        inputPlaceholder: placeholder,
        showCancelButton: true,
        allowOutsideClick: false,
        inputValidator: (value) => {
            if (!value) {
                return 'This field cannot be empty!';
            }
            if (validationFn && typeof validationFn === 'function') {
                const validationError = validationFn(value);
                if (validationError) {
                    return validationError; // This will display the validation error message
                }
            }
            return null;
        }
    });

    if (result.isConfirmed) {
        return result.value;
    } else {
        return undefined; // Handles the case when the user cancels the input
    }
};
