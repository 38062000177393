import React, { Component } from 'react';
import SideMenu from '../admin/SideMenu';
import { Modal } from 'reactstrap';
import TopMenu from '../admin/TopBar';
import { getDatabase, ref, onValue, update, get, remove, push } from 'firebase/database';
import '../../styles/css/AdminMain.css';
import UploadImage from '../utils/UploadImage';
import { showSuccessPopup, showErrorPopup, showInfoPopup } from '../utils/SwalConfig';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ReactPlayer from "react-player";
import { cleanCSVText, findFirstAndLastName, valueDoesExist, convertTimeStampToHumanReadable, cleanFirebaseText } from '../utils/HelpfulFunction';
import DatePicker from "../utils/DatePicker";

class SetUpGame extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
            questionsList: [],
            questionsAdded: [],
            tenantStats: {},
            userAnswers:[],
            currentGameVisitors: [],
            numberOfVotes: 2,
            unlimitedVotesAllowed: 'unlimited',
            currentGame: null,
            modal: false,
            userGameHistory: [],
            loading: true,
            wizardLocation: 'first',
            showStats: true,
            voteSessionInsideGame: false,
            sessionLength: 1,
            advanced: false,
            futureGamesList: []
        };
        this.handleChange = this.handleChange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleVideoChange = this.handleVideoChange.bind(this);
        this.database = getDatabase();
    }

    downloadUsers(){
        this.setState({loading: true});
        const vm = this;
        let csv = 'Email,First Name,Last Name,Phone Number,Zip Code,Birthday,Country,Other,Opt-In,Opt-In 2,Opt-In 3,Opt-In 4,Answer,Answer Time';
        const newColumnsArray = [];
        if(this.state.formQuestions){
            for(const i in this.state.formQuestions){
                const formQuestion = this.state.formQuestions[i];
                newColumnsArray.push(formQuestion.id)
                csv+=(","+cleanCSVText(formQuestion.text||""))
            }
            if(this.state.tenantVariables.otherResponse){
                csv+=(",Other")
            }
        }

        csv+="\n";

        const usersRef = ref(this.database, 'users');
        get(usersRef).then((snapshot) => {
            let users = snapshot.val() || {};
            const currentUsersState = vm.state.userAnswers;
            currentUsersState.forEach(function(row) {
                let {firstName, secondName} = findFirstAndLastName(users[row.uid].name);
                csv += (users[row.uid].email || row.uid || "");
                csv+= ",";
                csv+= (cleanCSVText(firstName) || "");
                csv+= ",";
                csv+= (cleanCSVText(secondName) || "");
                csv+= ",";
                csv+= (users[row.uid].phoneNumber || "");
                csv+= ",";
                csv+= (cleanCSVText(users[row.uid].zipCode) || "");
                csv+= ",";
                csv+= (users[row.uid].birthday || "");
                csv+= ",";
                csv+= (users[row.uid].country || "");
                csv+= ",";
                csv+= (cleanCSVText(users[row.uid].otherInput) || "");
                csv+= ",";
                csv+= (users[row.uid].optIn || "");
                csv+= ",";
                csv+= (users[row.uid].optInTwo || "");
                csv+= ",";
                csv+= (users[row.uid].optInThree || "");
                csv+= ",";
                csv+= (users[row.uid].optInFour || "");
                csv+= ",";
                csv+= cleanCSVText((valueDoesExist(row.answerText)?row.answerText:""));
                csv+= ",";
                csv+= convertTimeStampToHumanReadable(row.timeStamp);
                for(const indexOfColumnArray in newColumnsArray){
                    const columnHeaderName = newColumnsArray[indexOfColumnArray];
                    csv+= ",";
                    if(users[row.uid] && users[row.uid].form_responses && (users[row.uid].form_responses[columnHeaderName] === false || users[row.uid].form_responses[columnHeaderName] === true)){
                        csv += (users[row.uid].form_responses[columnHeaderName] || "");
                    } else {
                        csv += (row[columnHeaderName] || row[cleanFirebaseText(cleanCSVText(columnHeaderName))] || "");
                    }
                }
                if(vm.state.tenantVariables.otherResponse){
                    csv+= ",";
                    csv+= (cleanCSVText(users[row.uid].otherInput) || "");
                }
                csv += "\n";
            });
            const hiddenElement = document.createElement('a');
            hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
            hiddenElement.target = '_blank';
            const date_got = new Date((new Date().getTime() - new Date().getTimezoneOffset()*60*1000)).toISOString().split("T")[0] + ".csv";
            hiddenElement.download = "current_game_users_" + date_got;
            hiddenElement.click();
            vm.setState({loading: false});
        });
    }

    componentDidMount() {
        const tenantVariablesRef = ref(this.database, 'tenantVariables');
        const currentGameRef = ref(this.database, 'currentGame');
        const tenantStatsRef = ref(this.database, 'tenantStats');
        const questionsListRef = ref(this.database, 'questionsList');
        const currentGameVisitorsRef = ref(this.database, 'currentGameVisitors');
        const userAnswersRef = ref(this.database, 'userAnswers');
        const formQuestionsRef = ref(this.database, 'formQuestions');

        onValue(tenantVariablesRef, (snapshot) => {
            this.setState({ tenantVariables: snapshot.val() });
        });

        onValue(currentGameRef, (snapshot) => {
            const currentGame = snapshot.val();
            this.setState({ currentGame });
            if(currentGame && currentGame.id) {
                this.setGameToWatch(currentGame.id);
            }
        });

        onValue(tenantStatsRef, (snapshot) => {
            this.setState({ tenantStats: snapshot.val() });
        });

        onValue(questionsListRef, (snapshot) => {
            const data = snapshot.val();
            const questionsList = data ? Object.keys(data).map((key) => {
                return { id: key, ...data[key] };
            }) : [];
            this.setState({ questionsList });
        });

        onValue(currentGameVisitorsRef, (snapshot) => {
            this.setState({ currentGameVisitors: snapshot.val() ? Object.values(snapshot.val()) : [] });
        });

        onValue(userAnswersRef, (snapshot) => {
            this.setState({ userAnswers: snapshot.val() ? Object.values(snapshot.val()) : [] });
        });

        onValue(formQuestionsRef, (snapshot) => {
            this.setState({ formQuestions: snapshot.val() ? Object.values(snapshot.val()) : [] });
        });

        this.setState({loading: false});
    }

    setGameToWatch(id){
        const userGameHistoryRef = ref(this.database, `userGameHistory/${id}`);
        onValue(userGameHistoryRef, (snapshot) => {
            this.setState({ userGameHistory: snapshot.val() ? Object.values(snapshot.val()) : [] });
        });
    }

    async createGame(){
        const createGameObject = {};
        const gameId = push(ref(this.database)).key;
        const questionsAdded = this.state.questionsAdded;
        const game_name = this.state.game_name;
        let startTime = this.state.startTime;
        let endTime = this.state.endTime;
        let showScheduleTime = this.state.showScheduleTime;
        let gameTiming = this.state.gameTiming;
        const voteSession = this.state.voteSessionInsideGame || false;
        let sessionLength = this.state.sessionLength || 0;
        if(!voteSession){
            sessionLength = null;
        }
        let numberOfVotes = this.state.numberOfVotes || false;
        const unlimitedVotesAllowed = this.state.unlimitedVotesAllowed;
        const allowMultipleVotes = this.state.multipleVotes || false;
        let videoLink = this.state.videoLink || null;
        if(!this.state.advanced){
            videoLink = null;
        }
        if(allowMultipleVotes && unlimitedVotesAllowed === "unlimited"){
            numberOfVotes = false;
        }
        if(questionsAdded.length === 0){
            showInfoPopup("Missing Question", 'Go to the second tab to add questions');
            return;
        }
        if(!game_name){
            showInfoPopup("Missing Name For Game", 'Go to the first tab and add a name');
            return;
        }
        if(allowMultipleVotes && unlimitedVotesAllowed === 'limited' && !numberOfVotes){
            showInfoPopup("Need a limit to votes", 'If limited number of votes is selected you must enter a number of votes to allow');
            return;
        }
        if(voteSession && sessionLength > 0){
            sessionLength = sessionLength * 3600000;
        } else if(voteSession){
            showInfoPopup("Vote Session Error", "Vote session length must be a number and greater than 0. If you don't want a session length please uncheck the box");
            return;
        }

        if(showScheduleTime) {
            if(!startTime && gameTiming === "scheduled"){
                showInfoPopup("Hold On!", 'The game needs a start time');
                return;
            } else if(startTime.getTime() <= Date.now()){
                showInfoPopup("Hold On!", 'The start time cannot be before now');
                return;
            } else if(gameTiming === "scheduled" && !endTime) {
                showInfoPopup("Hold On!", 'The game needs an answer time if it is scheduled!');
                return;
            } else if(gameTiming === "scheduled" && endTime.getTime() <= Date.now()) {
                showInfoPopup("Hold On!", 'A scheduled game cannot have an answer time that is in the past');
                return;
            } else if(gameTiming === "scheduled" && endTime.getTime() <= startTime.getTime()) {
                showInfoPopup("Hold On!", 'A answer time cannot be before start time');
                return;
            } else if(gameTiming === "manual"){
                endTime = null;
            } else if(gameTiming === "immediate" && endTime){
                if(endTime <= Date.now()){
                    endTime = null;
                } else if (endTime.getTime() <= startTime.getTime()) {
                    showInfoPopup("Hold On!", 'A end time cannot be before start time');
                    return;
                }
            }
            if(startTime){
                startTime = startTime.getTime();
            }
            if(endTime){
                endTime = endTime.getTime();
            }
        } else {
            startTime = null;
            endTime = null;
        }

        if(startTime || endTime){
            createGameObject.scheduleInfo = {};
            createGameObject.scheduleInfo['status'] = 'scheduled';
            createGameObject.scheduleInfo['performAt'] = startTime;
            createGameObject.scheduleInfo['endAt'] = endTime;
        } else {
            createGameObject.scheduleInfo = null;
        }

        const players = [];
        const userAnswers = this.state.userAnswers;
        for(const answer in userAnswers){
            if(players.indexOf(userAnswers[answer].uid) === -1){
                players.push(userAnswers[answer].uid);
            }
        }
        const currentGame = this.state.currentGame || {};
        createGameObject.questions = questionsAdded;
        createGameObject.questionAnswers = null;
        createGameObject.question = null;
        createGameObject.active = false;
        createGameObject.answered = false;
        createGameObject.gameName = game_name;
        createGameObject.allowMultipleVotes = allowMultipleVotes;
        createGameObject.numberOfVotes = numberOfVotes;
        createGameObject.timeStamp = Date.now();
        createGameObject.sessionLength = sessionLength;
        createGameObject.voteSession = voteSession;
        createGameObject.id = gameId;
        createGameObject.showPercentages = null;
        createGameObject.gameType = "polling";
        createGameObject.endPollingVideoLink = videoLink || "";
        const vm = this;
        if((currentGame.gameName || currentGame.id) && !showScheduleTime){
            const onSaveAndClearSuccess = await this.saveAndClearGame(createGameObject, gameId);
            if(!onSaveAndClearSuccess){
                this.setState({loading: false})
                await showInfoPopup("Hold On!", 'Failed to clear out old game, please try again');
                return;
            }
        } else if(showScheduleTime && currentGame && currentGame.id) {
            const futureGamesRef = ref(this.database, `futureGamesList/${gameId}`);
            update(futureGamesRef, createGameObject).then(async () => {
                vm.setState({ modal: false });
                const futureGameList = await vm.getFutureGames();
                vm.setState({
                    futureGamesList: futureGameList,
                    loading: false
                });
                await showSuccessPopup('Game added to future games!', 'The game was successfully added to the future games list.');
            });
            return;
        } else {
            const currentGameRef = ref(this.database, 'currentGame');
            await update(currentGameRef, createGameObject);
        }
        this.setState({
            modal: false,
            userAnswers: [],
            currentGameVisitors: [],
            videoLink: null,
            advanced: false,
            videoFile: null,
            videoLength: null,
            fileUploaded: false,
            loading: false,
        });
        this.setGameToWatch(gameId);
        await showSuccessPopup('Game Created!', 'The game has been successfully created.');
    }

    async stopGame(){
        const currentGame = this.state.currentGame;
        currentGame.active = false;
        const currentGameRef = ref(this.database, 'currentGame');
        await update(currentGameRef, currentGame);
        this.setState({ currentGame });
        showErrorPopup('Game Stopped', 'The game has been stopped.');
    }

    async startGame() {
        const currentGame = this.state.currentGame;
        currentGame.active = true;
        const currentGameRef = ref(this.database, 'currentGame');
        await update(currentGameRef, currentGame);
        this.setState({ currentGame });
        showSuccessPopup('Game Started', 'The game has been started.');
    }

    endGame(){
        showInfoPopup('STOP!', 'Are you sure you want to do this? You will not be able to start this game again!')
        .then(async (result)=>{
            if(result.value){
                const currentGame = this.state.currentGame;
                currentGame.answered = true;
                const currentGameRef = ref(this.database, 'currentGame');
                await update(currentGameRef, currentGame);
                this.setState({ currentGame });
                showErrorPopup('Game Ended', 'The game has been ended.');
            }
        });
    }

    handleChange (evt) {
        const target = evt.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [evt.target.name]: value });
    }

    handleVideoChange(evt){
        const name_of_file = evt.target.name;
        const target = this[name_of_file];
        const file_to_update =target.files[0];
        this.setState({loading:true});
        const vm = this;
        this.UploadImage.upload_file(file_to_update).then(res => {
            vm.setState({loading:false});
            if(res && res.error){
                showErrorPopup('Video cannot be uploaded', res.error);
            } else if(res && res.imageUrl) {
                vm.setState({
                    fileUploaded: true,
                    videoLink: res.imageUrl
                });
            } else {
                showErrorPopup('Video cannot be uploaded', "Something went wrong, please re-upload your video and try again!");
            }
        });
    }

    toggle() {
        this.setState({
            modal: !this.state.modal,
            wizardLocation: 'first'
        });
    }

    resetGame(){
        showInfoPopup('STOP!', 'Are you sure you want to do this? This will erase all information gathered during this game! Only do this if no one has played yet.')
        .then((result)=>{
            if(result.value){
                const currentGameState = this.state.currentGame;
                currentGameState.active = false;
                currentGameState.answered = false;

                const history = this.state.userGameHistory;
                for(const historyRef in history){
                    const refInstance = history[historyRef];
                    const userGameHistoryRef = ref(this.database, `userGameHistory/${refInstance.key}/${currentGameState.id}`);
                    update(userGameHistoryRef, null);
                }
                this.setState({
                    userAnswers: [],
                    currentGameVisitors: [],
                    currentGame: currentGameState
                });
            }
        });
    }

    navButtonClicked(direction){
        const currentLocation = this.state.wizardLocation;
        if(direction === 'prev' && currentLocation === 'second'){
            this.setState({
                wizardLocation: 'first'
            });
            this.toggleWizardLocation('first', 'second', 'third');
        } else if(direction === 'prev' && currentLocation === 'third'){
            this.setState({
                wizardLocation: 'second'
            });
            this.toggleWizardLocation('second', 'first', 'third');
        } else if(direction === 'next' && currentLocation === 'first'){
            this.setState({
                wizardLocation: 'second',
            });
            this.toggleWizardLocation('second', 'first', 'third');
        } else if(direction === 'next' && currentLocation === 'second'){
            this.setState({
                wizardLocation: 'third',
            });
            this.toggleWizardLocation('third', 'first', 'second');
        } else {
            this.setState({
                wizardLocation: 'first'
            });
            this.toggleWizardLocation('first', 'second', 'third');
        }
    }

    async switchStatsPrizes(buttonClicked){
        if(buttonClicked === "showStats"){
            document.getElementById('showGames').classList.remove('active');
            document.getElementById('showStats').classList.add('active');
            this.setState({
                showStats:true,
                showGames:false
            });
        } else if(buttonClicked === "showGames"){
            document.getElementById('showGames').classList.add('active');
            document.getElementById('showStats').classList.remove('active');
            const futureGameList = await this.getFutureGames();
            this.setState({
                showStats:false,
                showGames:true,
                futureGamesList: futureGameList
            });
        }
    }

    async saveAndClearGame(futureGame, futureGameId){
        let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/saveAndClearGame`;
        if(process.env.NODE_ENV === "development"){
            url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api/saveAndClearGame`;
        }
        const bearerToken = await this.props.currentUser.getIdToken();
        const bearerTokenString = "Bearer " + bearerToken;
        this.setState({loading: true});
        let fetchObject = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': bearerTokenString
            },
            body: JSON.stringify({futureGame: futureGame, futureGameKey: futureGameId})
        };
        let response;
        try{
            response = await fetch(url, fetchObject);
            return !(!response || response.error);
        } catch (e) {
            console.log("ERROR: ", e);
            return false;
        }
    }

    async getFutureGames(numberOfResults = 10){
        const futureGamesRef = ref(this.database, 'futureGamesList');
        const snapshot = await get(futureGamesRef);
        return snapshot.val() ? Object.values(snapshot.val()) : [];
    }

    async makeFutureGameCurrent(gameToSetCurrent){
        let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/saveAndClearGame`;
        if(process.env.NODE_ENV === "development"){
            url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api/saveAndClearGame`;
        }
        const bearerToken = await this.props.currentUser.getIdToken();
        const bearerTokenString = "Bearer " + bearerToken;
        this.setState({loading: true});
        let fetchObject = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': bearerTokenString
            },
            body: JSON.stringify({futureGame: gameToSetCurrent, futureGameKey: gameToSetCurrent.id})
        };
        let response;
        try{
            response = await fetch(url, fetchObject);
        } catch (e) {
            this.setState({loading: false});
            console.log("ERROR: ", e);
            return false;
        }
        const responseJson = await Promise.resolve(response.json());
        if(responseJson.error){
            this.setState({loading: false});
            showErrorPopup("Oh No!", "Something went wrong, please try again!");
            return;
        }
        const futureGameList = await this.getFutureGames();
        this.setState({
            futureGamesList: futureGameList,
            loading:false
        });
        this.setGameToWatch(gameToSetCurrent.id);
        showSuccessPopup('Game made current!', 'The selected game is now the current game.');
    }

    async removeGameFromFutureList(gameToRemoveId){
        var futureGameList = this.state.futureGamesList;
        for(var gameIndex in futureGameList){
            var futureGame = futureGameList[gameIndex];
            if(futureGame.id === gameToRemoveId){
                futureGameList.splice(gameIndex, 1);
                if(gameToRemoveId){
                    const futureGamesRef = ref(this.database, `futureGamesList/${gameToRemoveId}`);
                    remove(futureGamesRef);
                }
            }
        }
        this.setState({
            futureGamesList: futureGameList
        });
    }

    toggleWizardLocation(tabClicked, otherTab1, otherTab2){
        this.setState({
            wizardLocation: tabClicked
        });
        document.getElementById(tabClicked).classList.add('active');
        document.getElementById(tabClicked).classList.add('show');
        document.getElementById(otherTab1).classList.remove('active');
        document.getElementById(otherTab1).classList.remove('show');
        const thirdTab = document.getElementById(otherTab2);
        const thirdTabOne = document.getElementById(otherTab2+'1');
        if(thirdTab && thirdTabOne){
            document.getElementById(otherTab2).classList.remove('active');
            document.getElementById(otherTab2).classList.remove('show');
            document.getElementById(otherTab2+'1').classList.remove('active');
            document.getElementById(otherTab2+'1').classList.remove('show');
        }
        document.getElementById(tabClicked+'1').classList.add('active');
        document.getElementById(tabClicked+'1').classList.add('show');
        document.getElementById(otherTab1+'1').classList.remove('active');
        document.getElementById(otherTab1+'1').classList.remove('show');
    }

    removeFromToArray(index, name){
        let removeArray = this.state[name];
        removeArray.splice(index, 1);
        this.setState({
            [name]: removeArray
        });
    }

    addQuestionToLocalArray(nameOfArray, selectName){
        let selectedQuestion = null;
        const questionsAddedArray = this.state[nameOfArray];
        const questionList = this.state.questionsList;
        for(const questionAddedIndex in questionsAddedArray){
            if(questionsAddedArray[questionAddedIndex].id === this.state[selectName]){
                showInfoPopup("Question Already In Game!", "This game already includes this question. Try adding another question.");
                return;
            }
        }
        for(const questionListIndex in this.state.questionsList){
            if(questionList[questionListIndex].id === this.state[selectName]){
                selectedQuestion = questionList[questionListIndex];
                if(questionList[questionListIndex].key && !selectedQuestion['id']){
                    selectedQuestion['id'] = questionList[questionListIndex].key;
                }
            }
        }
        if(selectedQuestion){
            questionsAddedArray.push(selectedQuestion);
            this.setState({
                [nameOfArray]: questionsAddedArray
            });
        }
    }

    async copyToClipBoard(link){
        try {
            await navigator.clipboard.writeText(link);
            showSuccessPopup('Link Copied!', 'The link has been copied to your clipboard.');
        }
        catch (err) {
            showErrorPopup('Link Failed To Copy', 'The link could not be copied. Please try again.');
        }
    }

    render() {
        let selectedGame = this.state.currentGame || null;
        const futureGamesList = this.state.futureGamesList || [];
        let questions = [];
        const players = [];
        let gameActive = false;
        let answered = false;
        let gameName = "";
        let timeFrame = "Game";
        let numberOfVotesAllowed = 1;
        const userAnswers = this.state.userAnswers;
        const userAnswersNumber = {};
        for(const answer in userAnswers){
            const key = userAnswers[answer].answerId;
            if(userAnswersNumber.hasOwnProperty(key)) {
                userAnswersNumber[key] += 1;
            } else {
                userAnswersNumber[key] = 1;
            }
            if(userAnswers[answer].uid && players.indexOf(userAnswers[answer].uid) === -1){
                players.push(userAnswers[answer].uid);
            }
        }
        const players_length = players.length;
        let hideEndGameButton = false;
        if(selectedGame && selectedGame.id){
            questions = selectedGame.questions || [];
            answered = selectedGame.answered;
            gameActive = selectedGame.active;
            gameName = selectedGame.gameName;
            if(selectedGame.voteSession){
                timeFrame = (selectedGame.sessionLength/3600000).toString() + " hours";
            }
            if(selectedGame.allowMultipleVotes){
                numberOfVotesAllowed = selectedGame.numberOfVotes || "Unlimited";
            }
            if(selectedGame.answered){
                hideEndGameButton = true;
            }
        } else if(selectedGame && !selectedGame.id){
            selectedGame = null;
        }
        return (
            <div className="admin-wrapper">
                <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none'}}/>
                <SideMenu/>
                <TopMenu/>
                <div className="admin-main-panel">
                    <div className="container" style={{padding:'20px', backgroundColor:'#e3eaef'}}>
                        <div className="row">
                            <div className="col-md-2" style={{display: selectedGame && answered ? '' : 'none'}}>
                                <div className="card" style={{backgroundColor:'#fe3b4b', width:'100%', textAlign: 'center', height:'50px'}}>
                                    <p style={{lineHeight:'50px'}}>Game Ended</p>
                                </div>
                            </div>
                            <div className="col-md-2" style={{display: !selectedGame || answered ? 'none' : ''}}>
                                <div className="card" style={{backgroundColor:'#00c78c', width:'100%', textAlign: 'center', height:'50px', display: gameActive ? '' : 'none', float:'left'}}>
                                    <p style={{lineHeight:'50px'}}>Game Is Active</p>
                                </div>
                                <div className="card" style={{backgroundColor:'#fe3b4b', width:'100%', textAlign: 'center', height:'50px', display: gameActive ? 'none' : '', float:'left'}}>
                                    <p style={{lineHeight:'50px'}}>Game is Inactive</p>
                                </div>
                            </div>
                            <div className="col-md-2" style={{display: !selectedGame || answered ? 'none' : ''}}>
                                <button onClick={() => this.startGame()} className="btn btn-primary btn-lg start-game-button" style={{display: gameActive ? 'none' : '', float:'left', height:'52px'}}>Start Game</button>
                                <button onClick={() => this.stopGame()} className="btn btn-primary btn-lg end-game-button" style={{display: gameActive ? '' : 'none', float:'left', height:'52px' }}>Stop Game</button>
                            </div>
                            <div className="col-md-2" style={{display: selectedGame ? '' : 'none'}}>
                                <button onClick={() => this.endGame()} className="btn btn-primary btn-lg end-game-button" style={{display: hideEndGameButton ? 'none' : '', float:'left', height:'52px' }}>End Game</button>
                            </div>
                            <div className="col-md-6">
                                <a className="btn btn-outline-danger btn-sm" onClick={() => this.resetGame()} style={{display: selectedGame && selectedGame.id ? '' : 'none', marginTop:'7px', float:'right'}}>Reset Game</a>
                                <button onClick={() => this.toggle()} className="btn btn-primary btn-lg create-game-button" style={{float:'right', marginRight:'5px'}}>New Game</button>
                            </div>
                        </div>
                    </div>
                    <div className="row" align="center" style={{justifyContent: "center"}}>
                        <h3 style={{color:'black', margin:'15px'}}>Game Set Up -- {gameName}</h3>
                    </div>
                    {selectedGame &&
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 justify-content-center" style={{textAlign:"center"}}>
                                    <h4 style={{color:'black'}}>Questions</h4>
                                    <Carousel style={{backgroundColor:'white'}} showArrows={true} showStatus={false} showIndicators={false} showThumbs={false} selectedItem={this.state.selectedItem} onChange={(e) => {this.setState({selectedItem: e,});}}>
                                        {
                                            questions.map(function(item, i){
                                                return <div style={{backgroundColor: "#FAFBFE", padding:10, height: "100%"}} key={i}>
                                                    <p style={{color:'black'}}>{i+1}/{questions.length.toString()}</p>
                                                    <p style={{color:'black'}}>{item.questionText}</p>
                                                    <ol style={{textAlign:'left'}}>
                                                        {
                                                            item.answers.map(function(answer,i) {
                                                                if(answer.correct){
                                                                    return <li key={i} style={{color:'green'}}>{answer.answerText}, {userAnswersNumber[answer.id] || 0}</li>
                                                                } else {
                                                                    return <li key={i} style={{color:'black'}}>{answer.answerText}, {userAnswersNumber[answer.id] || 0}</li>
                                                                }
                                                            })
                                                        }
                                                    </ol>
                                                    <div className='row' style={{marginTop: 10}}>
                                                        <div className="col-6" style={{textAlign:"right"}}>
                                                            <button style={{maxWidth: 200}} onClick={(e)=>this.copyToClipBoard(process.env.REACT_APP_FAN_LINK + "/bigscreen?questionid=" + (item.id || item.key))}>Copy Vertical Results Link</button>
                                                        </div>
                                                        <div className="col-6" style={{textAlign:"left"}}>
                                                            <button style={{maxWidth: 200}} onClick={(e)=>this.copyToClipBoard(process.env.REACT_APP_FAN_LINK + "/sidebigscreen?questionid=" + (item.id || item.key))}>Copy Horizontal Results Link</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            }, this)
                                        }
                                    </Carousel>
                                </div>
                                <div className="col-md-6">
                                    <h4 style={{color:'black'}}>Vote Settings</h4>
                                    <p style={{color:'black'}}>Number of Votes Allowed: {numberOfVotesAllowed}<br/>
                                        Votes good for: {timeFrame}
                                    </p>
                                </div>
                            </div>
                            <div style={{marginTop: 20}}>
                                <div className="export-button-styles btn btn-primary btn-lg download-button" onClick={()=>this.downloadUsers()}>
                                    <span className="fa fa-arrow-circle-down"/> Download Participants
                                </div>
                            </div>
                            <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                                <li className="nav-item" onClick={()=> this.switchStatsPrizes("showStats")}>
                                    <a href="#" aria-expanded="false" className="nav-link active" id="showStats" style={{backgroundColor:'#fafbfe'}}>
                                        <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                        <span className="d-none d-lg-block">Stats</span>
                                    </a>
                                </li>
                                <li className="nav-item" onClick={()=> this.switchStatsPrizes("showGames")}>
                                    <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showGames" style={{backgroundColor:'#fafbfe'}}>
                                        <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                                        <span className="d-none d-lg-block">Future Games</span>
                                    </a>
                                </li>
                            </ul>
                            {this.state.showStats &&
                                <div className="row">
                                    <div className="col-6">
                                        <div className="card card-styles text-xs-center">
                                            <div className="card-body" style={{textAlign: "center"}}>
                                                <blockquote className="card-bodyquote">
                                                    <p>Unique Players</p>
                                                    <footer className="value-text">{players_length}</footer>
                                                </blockquote>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="card card-styles text-xs-center">
                                            <div className="card-body" style={{textAlign: "center"}}>
                                                <blockquote className="card-bodyquote">
                                                    <p>All Answers</p>
                                                    <footer className="value-text">{userAnswers.length}</footer>
                                                </blockquote>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div style={{display: this.state.showGames ? 'block' : 'none', marginLeft: 10}}>
                                <p style={{color:'black', marginLeft:20, fontSize:20, marginTop:20}}>Future Games</p>
                                <table className="table table-striped" style={{color:'black'}}>
                                    <tbody>
                                    <tr>
                                        <th>Name</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Make Current Game</th>
                                        <th>Delete Game</th>
                                    </tr>
                                    {
                                        futureGamesList.map(function(item,i){
                                            let dateTime = convertTimeStampToHumanReadable(item.scheduleInfo.performAt);
                                            let endTime = convertTimeStampToHumanReadable(item.scheduleInfo.endAt);
                                            return <tr key={i}>
                                                <td style={{fontFamily:'Open Sans'}}>{item.gameName}</td>
                                                <td style={{fontFamily:'Open Sans' }}>{dateTime}</td>
                                                <td style={{fontFamily:'Open Sans' }}>{endTime || "None"}</td>
                                                <td style={{fontFamily:'Open Sans' }}><button className="btn btn-primary" onClick={()=>this.makeFutureGameCurrent(item)}>MAKE CURRENT</button></td>
                                                <td style={{fontFamily:'Open Sans' }}><button className="btn btn-danger" onClick={()=>this.removeGameFromFutureList(item.id)}>DELETE</button></td>
                                            </tr>;
                                        }, this)
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                </div>
                <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="header-title mb-3"> Create Game</h4>
                            <div id="rootwizard">
                                <ul className="nav nav-pills bg-dark-light nav-justified form-wizard-header mb-3">
                                    <li className="nav-item" onClick={() => this.toggleWizardLocation('first', 'third', 'second')}>
                                        <a href="#" className="nav-link rounded-0 pt-2 pb-2 active show" id="first1">
                                            <span className="fa fa-pencil-square-o"/>
                                            <span className="d-none d-sm-inline"> The Basics</span>
                                        </a>
                                    </li>

                                    <li className="nav-item" onClick={() => this.toggleWizardLocation('second', 'third', 'first')}>
                                        <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="second1">
                                            <span className="fa fa-question"/>
                                            <span className="d-none d-sm-inline"> Questions</span>
                                        </a>
                                    </li>

                                    <li className="nav-item" onClick={() => this.toggleWizardLocation('third', 'first', 'second')}>
                                        <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="third1">
                                            <span className="fa fa-sign-out"/>
                                            <span className="d-none d-sm-inline"> Finish</span>
                                        </a>
                                    </li>
                                </ul>

                                <div className="tab-content mb-0 b-0" style={{fontFamily:'Roboto'}}>

                                    <div className="tab-pane active show" id="first">
                                        <form id="accountForm" method="post" action="#" className="form-horizontal">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group row mb-3">
                                                        <label className="col-md-3 col-form-label" htmlFor="game_name"> Name</label>
                                                        <div className="col-md-9">
                                                            <input type="text" className="form-control" name="game_name" id="game_name" value={this.state.game_name} onChange={this.handleChange}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="showScheduleTime">Set Schedule Time</label>
                                                <br/>
                                                <input type="checkbox" checked={this.state.showScheduleTime} id="showScheduleTime" name="showScheduleTime" onChange={this.handleChange}/>
                                            </div>
                                            {this.state.showScheduleTime &&
                                                <>
                                                    <div className="form-group row mb-3">
                                                        <label className="col-md-3 col-form-label" htmlFor="startTime"> Set Start Time</label>
                                                        <div className="col-md-9">
                                                            <DatePicker showTimeInput dateFormat="Pp" selected={this.state.startTime} onChange={date => this.setState({startTime: date})}/>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row mb-3">
                                                        <label className="col-md-3 col-form-label" htmlFor="endTime"> Set Stop Time</label>
                                                        <div className="col-md-9">
                                                            <DatePicker showTimeInput dateFormat="Pp" selected={this.state.endTime} onChange={date => this.setState({endTime: date})}/>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            <div className="row">
                                                <label className="col-md-3" htmlFor="voteSessionInsideGame">Time Duration</label>
                                                <div className="col-md-9">
                                                    <input value={this.state.voteSessionInsideGame} name="voteSessionInsideGame" type="checkbox" checked={this.state.voteSessionInsideGame} onChange={this.handleChange} />
                                                </div>
                                            </div>
                                            <div className="row" style={{display: this.state.voteSessionInsideGame ? '' : "none"}}>
                                                <div className="col-12">
                                                    <div className="form-group row mb-3">
                                                        <label className="col-md-3 col-form-label" htmlFor="sessionLength"> Session Length (hours)</label>
                                                        <div className="col-md-3">
                                                            <input type="number" className="form-control" name="sessionLength" id="sessionLength" value={this.state.sessionLength} onChange={this.handleChange} min="1"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <label className="col-md-3" htmlFor="multipleVotes">Allow Multiple Votes</label>
                                                <div className="col-md-9">
                                                    <input value={this.state.multipleVotes} name="multipleVotes" type="checkbox" checked={this.state.multipleVotes} onChange={this.handleChange} />
                                                </div>
                                            </div>
                                            <div className="row" style={{display: this.state.multipleVotes ? '' : "none"}}>
                                                <div className="col-12">
                                                    <div className="form-group row mb-3">
                                                        <label className="col-md-12 col-form-label" htmlFor="name3"> Votes Allowed</label>
                                                        <div className="col-md-9" onChange={this.handleChange}>
                                                            <input type="radio" value="unlimited" name="unlimitedVotesAllowed" checked={this.state.unlimitedVotesAllowed === "unlimited"}/> <span style={{marginRight:10, fontSize:10}}>Unlimited</span>
                                                            <input type="radio" value="limited" name="unlimitedVotesAllowed" checked={this.state.unlimitedVotesAllowed === "limited"}/> <span style={{marginRight:10, fontSize:10}}>Limited</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" style={{display: this.state.unlimitedVotesAllowed === "limited" && this.state.multipleVotes? '' : "none"}}>
                                                <div className="col-12">
                                                    <div className="form-group row mb-3">
                                                        <label className="col-md-3 col-form-label" htmlFor="name3"> Number of Votes Per User</label>
                                                        <div className="col-md-3">
                                                            <input type="number" className="form-control" name="numberOfVotes" id="numberOfVotes" value={this.state.numberOfVotes} onChange={this.handleChange} min="2"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label htmlFor="showAdvancedSettings">Advanced</label>
                                                        <br/>
                                                        <input type="checkbox" checked={this.state.advanced} id="advanced" name="advanced" onChange={this.handleChange}/>
                                                    </div>
                                                    {this.state.advanced &&
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="form-group row mb-3">
                                                                    <label className="col-md-12 col-form-label" htmlFor="name3"> Add Ending Video</label>
                                                                    <div className="form-group row" style={{textAlign: "center", alignItems:"center", display: this.state.fileUploaded? 'none' : ''}}>
                                                                        <div className="col-md-6">
                                                                            <label htmlFor="videoLink">Link</label>
                                                                            <input type="url" id="videoLink" name="videoLink" className="form-control" value={this.state.videoLink} onChange={this.handleChange} placeholder="https://endvideolink.com"/>
                                                                        </div>
                                                                        <div className="col-md-1">
                                                                            <h2><b>OR</b></h2>
                                                                        </div>
                                                                        <div className="col-md-2">
                                                                            <input style={{display:'none'}}  id="videoFile" name="videoFile" type="file" ref={input => {this.videoFile = input; }} onChange={this.handleVideoChange}/>
                                                                            <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Video (MP4)" onClick={() => document.getElementById('videoFile').click()} />
                                                                        </div>
                                                                    </div>
                                                                    <div style={{width: 300, margin: "auto", textAlign: "center", display: this.state.videoLink? '' : 'none'}}>
                                                                        Preview <span style={{cursor:"pointer"}} onClick={()=>this.setState({videoLink: null, fileUploaded:false}, () => {
                                                                        document.getElementById('videoLink').value="";
                                                                    })}> ❌</span>
                                                                        {this.state.add_video && this.state.videoLink && !this.state.videoLength &&
                                                                            <p style={{color:"red"}}>Video error: video couldn't play or is still loading</p>
                                                                        }
                                                                        <ReactPlayer
                                                                            style={{display: this.state.videoLink? '' : 'none'}}
                                                                            url={this.state.videoLink}
                                                                            onDuration={(e)=> this.setState({videoLength: e})}
                                                                            onError={(e)=>this.setState({videoLength: null})}
                                                                            muted={true}
                                                                            playing={true}
                                                                            controls={true}
                                                                            preload={"auto"}
                                                                            width="100%"
                                                                            height="auto"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                    <div className="tab-pane fade" id="second">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group row mb-3">
                                                    <label className="col-md-3 col-form-label" htmlFor="name3"> Select Question(s)</label>
                                                    <div className="col-md-9">
                                                        <select className="form-control" name="question_select" id="question_select" value={this.state.question_select} onChange={this.handleChange}>
                                                            <option/>
                                                            {
                                                                this.state.questionsList.map(function(item,index){
                                                                    return(
                                                                        <option value={item.key || item.id} key={index}>{item.questionText}</option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <ol style={{listStylePosition: 'inside', textAlign:'left'}} className="offset-md-0 col-md-10">
                                                {
                                                    this.state.questionsAdded.map(function(item,index){
                                                        return(
                                                            <div key={index} className="form-group">
                                                                <li>{item.questionText} <span style={{float:'right'}} className="fa fa-trash-o" onClick={()=>this.removeFromToArray(index, "questionsAdded")}/></li>
                                                            </div>
                                                        );
                                                    }, this)
                                                }
                                            </ol>
                                        </div>
                                        <div className="row" style={{justifyContent: "center"}}>
                                            <div className="form-group row" align="center">
                                                <div className="col-md-12">
                                                    <button className="btn btn-primary btn-admin" onClick={()=>this.addQuestionToLocalArray("questionsAdded", "question_select")}>Add Question</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="third">
                                        <form id="otherForm" method="post" action="#" className="form-horizontal"/>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="text-center">
                                                    <h2 className="mt-0">
                                                        <i className="mdi mdi-check-all"/>
                                                    </h2>
                                                    <h3 className="mt-0">Finish</h3>

                                                    <div className="row form-group">
                                                        <div className="col-md-12">
                                                            <p>You are all set to create a polling game!  Before clicking "Create Game" make sure all the settings are the way you want. Feel free to navigate back to edit anything you like!</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <ul className="list-inline wizard mb-0">
                                        <li className="previous list-inline-item" style={{display: this.state.wizardLocation === 'first' ? 'none' : '' }}><a href="#" className="btn btn-primary btn-lg previous-wizard-button" onClick={() => this.navButtonClicked('prev')}>Previous</a>
                                        </li>
                                        <li className="next list-inline-item float-right"><a href="#" className="btn btn-primary btn-lg next-wizard-button" onClick={() => this.navButtonClicked('next')} style={{display: this.state.wizardLocation === 'third' ? 'none' : '' }}>Next</a></li>
                                        <li className="next list-inline-item float-right"><a href="#" className="btn btn-primary btn-lg creategame-wizard-button" onClick={() => this.createGame()} style={{display: this.state.wizardLocation === 'third' ? '' : 'none' }}>Create Game</a></li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default SetUpGame;
